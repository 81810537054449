// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-dokument-js": () => import("./../../../src/pages/dokument.js" /* webpackChunkName: "component---src-pages-dokument-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nyhet-js": () => import("./../../../src/pages/nyhet.js" /* webpackChunkName: "component---src-pages-nyhet-js" */),
  "component---src-pages-nyheter-js": () => import("./../../../src/pages/nyheter.js" /* webpackChunkName: "component---src-pages-nyheter-js" */),
  "component---src-pages-om-oss-js": () => import("./../../../src/pages/om-oss.js" /* webpackChunkName: "component---src-pages-om-oss-js" */),
  "component---src-pages-pdf-informationssakerhetspolicy-js": () => import("./../../../src/pages/pdf/informationssakerhetspolicy.js" /* webpackChunkName: "component---src-pages-pdf-informationssakerhetspolicy-js" */),
  "component---src-pages-pdf-integritetspolicy-js": () => import("./../../../src/pages/pdf/integritetspolicy.js" /* webpackChunkName: "component---src-pages-pdf-integritetspolicy-js" */),
  "component---src-pages-pdf-kvalitetspolicy-js": () => import("./../../../src/pages/pdf/kvalitetspolicy.js" /* webpackChunkName: "component---src-pages-pdf-kvalitetspolicy-js" */),
  "component---src-pages-pdf-miljopolicy-js": () => import("./../../../src/pages/pdf/miljopolicy.js" /* webpackChunkName: "component---src-pages-pdf-miljopolicy-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-skanners-js": () => import("./../../../src/pages/skanners.js" /* webpackChunkName: "component---src-pages-skanners-js" */),
  "component---src-pages-skanningsprogramvara-js": () => import("./../../../src/pages/skanningsprogramvara.js" /* webpackChunkName: "component---src-pages-skanningsprogramvara-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */)
}

